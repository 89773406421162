import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import EditIcon from "@mui/icons-material/Edit";

import { useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import CardEdit from "components/CardEdit";
import { useEffect, useState } from "react";
import { maskCNPJ, maskCPF, maskDate, maskMoney } from "utils/maskOutputs";
import useVerifyScreen from "hooks/useVerifyScreen";

import { checkPattern } from "utils/helpers";
import { Historic } from "pages/DetailProposal/Historic";
import { DETAIL_OCCURRENCE } from "graphql/query/detailOccurrence";
import {
  DetailOccurrenceAttachmentsResponse,
  DetailOccurrenceHistoryResponse,
  QueryRoot,
} from "graphql/gql/graphql";
import { getStatusColor } from "utils/getStatusColor";
import { CardUpFile } from "pages/DetailProposal/CardUpFile";
import { CardUpFileOccurrence } from "./CardUpFileOccurrence";

import { useNavigate } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Modal } from "components/Modal";
import { EditStatus } from "./EditStatus";

export function OccurrenceDetail() {
  const isMobile = useVerifyScreen();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const location = useLocation();
  const occurrenceId = location.state?.id;

  const fromContract: boolean = checkPattern(pathname, /^\/contrato\/\d+$/);

  const [formDetail, setFormDetail] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data, loading } = useQuery<QueryRoot>(DETAIL_OCCURRENCE, {
    variables: {
      id: occurrenceId,
    },
  });

  const handleBack = () => {
    navigate(-1)
  }

  const ocurrence = data?.detailOccurrence;

  const isPhone = useMediaQuery("(max-width: 500px) ");
  const isBigScreen = useMediaQuery("(max-width: 1400px) ");
  const isDesktop = useMediaQuery("(max-width: 1000px) ");

  function tryParseJSON(jsonString: string) {
    try {
      const json = JSON.parse(jsonString);
      if (json && typeof json === "object") {
        return json;
      }
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    if (data && !loading) {
      setFormDetail({
        tenantData: {
          contractId: ocurrence?.contract?.id,
          name: ocurrence?.contract?.proposal?.tenant?.name,
          cpf: ocurrence?.contract?.proposal?.tenant?.document,
          code: ocurrence?.contract?.proposal?.code,
          proposalId: ocurrence?.contract?.proposal?.id,
        },
        rentalData: {
          type: ocurrence?.type,
          value: ocurrence?.value,
          dueDate: ocurrence?.dead_line,
          rentAmount: ocurrence?.rent_amount,
          condominiumAmount: ocurrence?.condominium_amount,
          iptu: ocurrence?.iptu,
          otherTaxes: ocurrence?.other_taxes,
          copasa: ocurrence?.copasa,
          cemig: ocurrence?.cemig,
          repairs: ocurrence?.repairs,
          contractualFine: ocurrence?.contractual_fine,
          description: ocurrence?.description,
        },
        paymentDetails: {
          name: ocurrence?.contract?.proposal?.real_estate?.name,
          document: ocurrence?.contract?.proposal?.real_estate?.document,
          bank: ocurrence?.contract?.proposal?.real_estate?.bank_data?.bank,
          agency: ocurrence?.contract?.proposal?.real_estate?.bank_data?.agency,
          account:
            ocurrence?.contract?.proposal?.real_estate?.bank_data?.account,
          accountType:
            ocurrence?.contract?.proposal?.real_estate?.bank_data?.account_type,
        },
      });
    }
  }, [ocurrence]);

  const transformArray = (formItem: "rentalEl" | "paymentEl" | "tenantEl") => {
    if (Object.keys(formDetail).length > 0) {
      const { rentalData, paymentDetails, tenantData } = formDetail;

      const data = {
        tenantEl: [
          {
            title: "Contrato",
            value: (
              <Link to={`/contrato/${tenantData.proposalId}`}>
                {tenantData.code}
              </Link>
            ),
          },
          { title: "Locatário", value: tenantData.name },
          { title: "CPF", value: maskCPF(tenantData.cpf) },
        ],
        rentalEl: [
          { title: "Tipo", value: rentalData.type },
          { title: "Valor original", value: maskMoney(rentalData.value) },
          { title: "Vencimento", value: maskDate(rentalData.dueDate) },
          { title: "Aluguel", value: maskMoney(rentalData.rentAmount) },
          { title: "Condomínio", value: maskMoney(rentalData.condominiumAmount) },
          { title: "IPTU", value: maskMoney(rentalData.iptu) },
          { title: "Outras taxas", value: maskMoney(rentalData.otherTaxes) },
          { title: "Copasa", value: rentalData.copasa ? maskMoney(rentalData.copasa) : "-" },
          { title: "Cemig", value: rentalData.cemig ? maskMoney(rentalData.cemig) : "-" },
          { title: "Reparos", value: rentalData.repairs ? maskMoney(rentalData.repairs) : "-" },
          {
            title: "Multa contratual",
            value: rentalData.repairs ? maskMoney(rentalData.contractualFine) : "-",
          },
          { title: "Descrição", value: rentalData.description },
        ],
        paymentEl: [
          { title: "Beneficiário", value: paymentDetails.name },
          {
            title: "CPF/CNPJ",
            value:
              paymentDetails.document.lenght > 11
                ? maskCNPJ(paymentDetails.document)
                : maskCPF(paymentDetails.document),
          },
          { title: "Banco", value: formDetail.paymentDetails.bank ? (tryParseJSON(formDetail.paymentDetails.bank)?.name || "-") : "-" },
          { title: "Agência", value: paymentDetails.agency },
          { title: "Conta", value: paymentDetails.account },
          { title: "Tipo de conta", value: paymentDetails.accountType },
        ],
      };
      return data[formItem];
    }
  };

  if (loading || !data)
    return (
      <Box width="100%" textAlign="center" mt={6}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box display="flex" alignItems="center">
        <IconButton onClick={handleBack}>
          <ArrowBackIosRoundedIcon sx={{ color: "#2E349F" }} />
        </IconButton>
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "900",
            color: "#2E349F",
            textAlign: "start",
          }}
        >
          Ocorrência
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          color: "#2E349F",
          gap: "3rem",
          padding: "2rem 0 2rem 0",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <PersonIcon />
          <Typography sx={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
            <b>Locatário: </b>
            {ocurrence?.contract?.proposal?.tenant?.name}
          </Typography>
        </Box>

        <Box sx={{ display: "flex" }}>
          <EventIcon />
          <Typography sx={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
            <b>Data de Criação: </b>
            {maskDate(ocurrence?.contract?.proposal?.tenant?.created_at || "")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          color: "#2E349F",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: isPhone ? "column" : "row",
            justifyContent: "space-between",
            gap: isBigScreen ? "2rem" : 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
            }}
          >
            <Typography sx={{ fontSize: "1rem" }}>
              <b>Solicitação:</b> {ocurrence?.contract?.proposal?.code}
            </Typography>

            <Chip
              label={ocurrence?.status}
              sx={{
                marginTop: 1,
                fontWeight: "bolder",
                maxWidth: 200,
                backgroundColor: getStatusColor(
                  "Ocorrencia",
                  ocurrence?.status || ""
                ),
              }}
            />
          </Box>
          {ocurrence?.status === "Exonerado" && (
            <Alert style={{ alignItems: "center", fontSize: 18 }} severity="error">Este locatário está exonerado.</Alert>
          )}
        </Box>
      </Box>

      <Grid
        container
        spacing={3}
        sx={{
          marginTop: "2rem",
          display: "flex",
          gap: isPhone ? "1rem" : "4rem",
        }}
      >
        <Grid item xs={11} sm={12} md={isDesktop ? 12 : 5}>
          <CardEdit
            {...{ formDetail, fromContract }}
            title="Dados do Contrato"
            elements={transformArray("tenantEl")}
            form="tenant"
            fromContract
          />

          <CardEdit
            {...{ formDetail, fromContract }}
            title="Dados da Inadimplência"
            elements={transformArray("rentalEl")}
            form="location"
            isOccurrence
            occurrenceId={occurrenceId}
          />
          <CardEdit
            {...{ formDetail, fromContract }}
            title="Dados bancários para pagamento"
            elements={transformArray("paymentEl")}
            form="payment"
            fromContract
          />

          <CardUpFileOccurrence
            attachments={
              ocurrence?.attachments?.filter(
                (
                  attachment
                ): attachment is DetailOccurrenceAttachmentsResponse =>
                  attachment !== null
              ) || []
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={isDesktop ? 12 : 5}>
          <Historic
            timeline={(ocurrence?.contract?.proposal?.history || [])
              .filter(
                (item): item is DetailOccurrenceHistoryResponse =>
                  item !== null && item !== undefined
              )
              .map((item: DetailOccurrenceHistoryResponse) => ({
                title: item.title || "",
                person_id: item.person_id || 0,
                person_name: item.person_name || "",
                description: item.description || "",
                updated_at: item.updated_at || "",
                __typename: item.__typename || "",
              }))}
          />
        </Grid>
      </Grid>

      <Modal
        title="Editar Status da Ocorrência"
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        children={
          <EditStatus
            id={occurrenceId}
            occurrenceStatus={ocurrence?.status || ""}
            closeModal={() => setOpenModal(false)}
          />
        }
      />
    </Box>
  );
}
